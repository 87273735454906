import React from 'react';
import './global.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import TradingSystem from './components/TradingSystem';
import Partners from './components/Partners';
import RiskManagement from './components/RiskManagement';
import TeamAdvantages from './components/TeamAdvantages';
import Contact from './components/Contact';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <main>
        <div className="content-wrapper">
          <AboutUs />
          <Services />
          <TradingSystem />
          <Partners />
          <RiskManagement />
        </div>
      </main>
      <Contact />
    </div>
  );
}

export default App;

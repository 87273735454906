import React from 'react';
import styles from './TradingSystem.module.css';
import { FaWater, FaShieldAlt, FaClock, FaCogs } from 'react-icons/fa';


const TradingSystem = () => {
  return (
    <div id="tradingSystem" className={styles.tradingSystem}>
      <h2>Advantages</h2>
      <div className={styles.advantagesContainer}>
        <div className={styles.advantageRow}>
          <div className={styles.advantageCard}>
            <FaWater className={styles.icon} />
            <h3>High-Performance Trading System</h3>
            <p>Independently developed high-performance trading system with millisecond-level trade execution speed, ensuring every trading opportunity is captured in the fast-changing market.</p>
          </div>
          <div className={styles.advantageCard}>
            <FaShieldAlt className={styles.icon} />
            <h3>Intelligent Algorithm Strategies</h3>
            <p>Utilizing advanced algorithmic trading strategies combined with artificial intelligence and machine learning technologies to automatically adapt to market changes, optimize trade execution, and enhance efficiency and returns.</p>
          </div>
        </div>
        <div className={styles.advantageRow}>
          <div className={styles.advantageCard}>
            <FaClock className={styles.icon} />
            <h3>Stability and Reliability</h3>
            <p>The system boasts high stability and availability, operating uninterrupted 24/7 to ensure continuous and reliable trading.</p>
          </div>
          <div className={styles.advantageCard}>
            <FaCogs className={styles.icon} />
            <h3>Security Assurance</h3>
            <p>Multi-layered security protections, including data encryption, access control, and risk warnings, comprehensively safeguard trading security.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingSystem;
import React from 'react';
import styles from './Contact.module.css';
import logo from '../images/logo.png';
import { FaTwitter, FaEnvelope } from 'react-icons/fa';

const Contact = () => {
  return (
    <div id="contact" className={styles.contact}>
      {/* 现有的 Contact 组件内容 */}
      <footer className={styles.footer}>
        <div className={styles.footerBackground}>
          <div className={`${styles.footerContent} container`}>
            <div className={styles.logoSection}>
              <img src={logo} alt="CryptoFlow Logo" className={styles.footerLogo} />
            </div>
            <div className={styles.copyrightSection}>
              <p>&copy; 2024 CryptoFlow. All rights reserved.</p>
            </div>
            <div className={styles.socialSection}>
              <a href="https://x.com/cryptoflowHQ" target="_blank" rel="noopener noreferrer" className={styles.socialLink}>
                <FaTwitter className={styles.socialIcon} />
              </a>
              <a href="mailto:info@cryptoflow.global" className={styles.socialLink}>
                <FaEnvelope className={styles.socialIcon} />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Contact;

import React from 'react';
import styles from './Partners.module.css';

// 假设这些是合作伙伴的 logo 图片
import partner1 from '../images/partners/Gate.png';
import partner2 from '../images/partners/bitget.png';
import partner3 from '../images/partners/bybit.png';
import partner4 from '../images/partners/digitata.png';
import partner5 from '../images/partners/dmail.webp';
import partner6 from '../images/partners/kucoin.png';
import partner7 from '../images/partners/mexc.png';
import partner8 from '../images/partners/nexgami.png';
import partner9 from '../images/partners/okx.png';
import partner10 from '../images/partners/zkf.webp';

const partners = [
  { name: 'Partner 1', logo: partner1 },
  { name: 'Partner 2', logo: partner2 },
  { name: 'Partner 3', logo: partner3 },
  { name: 'Partner 4', logo: partner4 },
  { name: 'Partner 5', logo: partner5 },
  { name: 'Partner 6', logo: partner6 },
  { name: 'Partner 7', logo: partner7 },
  { name: 'Partner 8', logo: partner8 },
  { name: 'Partner 9', logo: partner9 },
  { name: 'Partner 10', logo: partner10 },
];

const Partners = () => {
  return (
    <div id="partners" className={styles.partners}>
      <h2>Partners</h2>
      <div className={styles.partnerGrid}>
        {partners.map((partner, index) => (
          <div key={index} className={styles.partnerLogo}>
            <img src={partner.logo} alt={partner.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;
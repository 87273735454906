import React from 'react';
import styles from './AboutUs.module.css';

const AboutUs = () => {
  return (
    <div id="aboutUs" className={styles.aboutUs}>
      <h2>About Us</h2>
      <div className={styles.description}>
        <p>
          CryptoFlow is a leading cryptocurrency trading and investment firm, dedicated to providing innovative solutions in the digital asset space. Our team of experts combines deep industry knowledge with cutting-edge technology to deliver exceptional results for our clients.
        </p>
      </div>
      <ul className={styles.features}>
        <li className={styles.featureItem}>
          <h3>Expert Team</h3>
          <p>Our team consists of seasoned professionals with extensive experience in finance, technology, and cryptocurrency markets.</p>
        </li>
        <li className={styles.featureItem}>
          <h3>Advanced Technology</h3>
          <p>We leverage state-of-the-art trading algorithms and infrastructure to ensure optimal performance and security.</p>
        </li>
        <li className={styles.featureItem}>
          <h3>Client-Centric Approach</h3>
          <p>We prioritize our clients' needs, offering personalized solutions and dedicated support to help achieve their financial goals.</p>
        </li>
      </ul>
    </div>
  );
};

export default AboutUs;

import React from 'react';
import styles from './Hero.module.css';
import logo from '../images/logo.png';
import backgroundImage from '../images/graphic.png'; // 确保这个路径正确

const Hero = () => {
  return (
    <div className={styles.hero} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div aria-label="shadow" className={styles.shadow}></div>
      <div className={styles.content}>
        <h1>Liquidity in motion, trust in excellence.</h1>
        <p>Trust is the cornerstone of the industry. With exceptional risk management and professional expertise, we are committed to delivering top-notch services that maximize value for our clients.</p>
      </div>
    </div>
  );
};

export default Hero;

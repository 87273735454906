import React from 'react';
import styles from './RiskManagement.module.css';
import { FaShieldAlt, FaBalanceScale, FaChartLine, FaUserShield } from 'react-icons/fa';

const RiskManagement = () => {
  return (
    <div id="riskManagement" className={styles.riskManagement}>
      <h2>Risk Management</h2>
      <div className={styles.riskGrid}>
        <div className={styles.riskItem}>
          <FaShieldAlt className={styles.icon} />
          <h3>Comprehensive Risk Control</h3>
          <p>Established a comprehensive risk management system to monitor and manage market risk, credit risk, and operational risk in all aspects, reducing potential losses.</p>
        </div>
        <div className={styles.riskItem}>
          <FaBalanceScale className={styles.icon} />
          <h3>Compliance Operation</h3>
          <p>Strictly adhering to laws and regulations in various regions, actively communicating with regulatory authorities to ensure all business activities are legal and compliant, protecting the rights and interests of our partners.</p>
        </div>
        <div className={styles.riskItem}>
          <FaChartLine className={styles.icon} />
          <h3>Security Protection</h3>
          <p>Employing advanced network security technologies to prevent various cyber attacks, protecting the security of trading systems and client assets.</p>
        </div>
        <div className={styles.riskItem}>
          <FaUserShield className={styles.icon} />
          <h3>Real-Time Monitoring</h3>
          <p>Continuously monitoring market dynamics and trading behaviors, promptly warning and handling abnormal situations to ensure stable business operations.</p>
        </div>
      </div>
    </div>
  );
};

export default RiskManagement;
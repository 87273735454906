import React from 'react';
import styles from './Services.module.css';
import { FaChartLine, FaShieldAlt, FaUserTie } from 'react-icons/fa'; // 使用 react-icons 库

const Service = () => {
  return (
    <div id="service" className={styles.service}>
      <h2>Services</h2>
      <div className={styles.serviceContainer}>
        <div className={styles.serviceCard}>
          <FaChartLine className={styles.icon} />
          <h3>Listing Consultation</h3>
          <p>We offer professional listing consultation services for digital asset projects intending to list on mainstream exchanges. This includes assisting project teams in formulating listing strategies, compliance advice, market positioning analysis, and more, helping them successfully land on trading platforms and gain broader market recognition.</p>
        </div>
        <div className={styles.serviceCard}>
          <FaShieldAlt className={styles.icon} />
          <h3>Market Making Services</h3>
          <p>Providing comprehensive market-making services using advanced trading algorithms and strategies to enhance trading depth, reduce bid-ask spreads, and improve market liquidity for trading platforms and project teams, creating an optimal trading environment.</p>
        </div>
        <div className={styles.serviceCard}>
          <FaUserTie className={styles.icon} />
          <h3>Investment</h3>
          <p>Actively identifying promising blockchain projects, we provide financial support and resource incubation. Through investment and incubation, we help projects grow rapidly, promote the development of innovative technologies, and achieve win-win cooperation.</p>
        </div>
      </div>
    </div>
  );
};

export default Service;
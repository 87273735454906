import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import styles from './Nav.module.css';
import logoPNG from '../images/logo.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        <img 
          src={logoPNG} 
          alt="CryptoFlow" 
          className={styles.logoImage}
        />
      </div>
      {isMobile ? (
        <>
          <button className={styles.menuButton} onClick={toggleMenu}>
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
          {isMenuOpen && (
            <div className={styles.mobileMenu}>
              <a href="#home" onClick={toggleMenu}>Home</a>
              <a href="#aboutUs" onClick={toggleMenu}>About</a>
              <a href="#service" onClick={toggleMenu}>Services</a>
              <a href="#partners" onClick={toggleMenu}>Partners</a>
              <a href="#riskManagement" onClick={toggleMenu}>Risk Management</a>
            </div>
          )}
        </>
      ) : (
        <div className={styles.navLinks}>
          <a href="#home">Home</a>
          <a href="#aboutUs">About</a>
          <a href="#service">Services</a>
          <a href="#partners">Partners</a>
          <a href="#riskManagement">Risk Management</a>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
